var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-toolbar',{attrs:{"tile":"","elevation":"1"}},[_c('v-app-bar-nav-icon'),_c('v-toolbar-title',[_vm._v("Accounts")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"to":"/secure/accounts/new","fab":"","color":"success","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("New account")])])],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-text-field',{staticStyle:{"margin-bottom":"10px"},attrs:{"label":"Filter by username","prepend-inner-icon":"mdi-magnify","rules":_vm.searchTextRules,"min":"3","counter":""},on:{"keyup":_vm.updateSearch,"blur":_vm.searchBlur},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"accounts_data_table","headers":_vm.headers,"items":_vm.accounts,"loading":_vm.accounts_loading},on:{"contextmenu:row":_vm.show},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){return [_c('v-icon',[_vm._v("mdi-account-circle")])]}},{key:"item.is_administrator",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_administrator ? 'Yes' : 'No')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap !important"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"actionButtons",attrs:{"depressed":"","small":"","dark":"","color":"grey darken-3","to":'files/' + item.username}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-folder")])],1)]}}],null,true)},[_c('span',[_vm._v("User files")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"actionButtons",attrs:{"depressed":"","small":"","dark":"","color":"primary","to":'account/' + item.username}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit account")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"actionButtons",attrs:{"depressed":"","small":"","dark":"","color":"error"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true
                                        _vm.userToDelete = item.username}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete account")])])],1)]}}],null,true)})],1)],1),_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":""},model:{value:(_vm.showMenuRightClick),callback:function ($$v) {_vm.showMenuRightClick=$$v},expression:"showMenuRightClick"}},[_c('v-list',_vm._l((_vm.accountsActionItems),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay,"z-index":"100000"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Delete user "+_vm._s(_vm.userToDelete)+" ")]),_c('v-card-text',[_vm._v(" To confirm the deletion of this user, enter the username in the field below, and then click on the confirm button. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"info","text":""},on:{"click":function($event){_vm.overlay = !_vm.overlay}}},[_vm._v(" Confirm ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Warning ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.errorDialogMessage)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.errorDialog = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }