import Vue from 'vue'
import Vuex from 'vuex'
import { locStorage, loadData } from '@/plugins/store-localstorage'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        dark: loadData('dark') || false,
        // fullWidth: loadData('fullWidth') || false,
        token: loadData('token'),
        username: loadData('username')
    },
    mutations: {
        updateData(state, modData) {
            console.log(`updating ${modData.k}`)
            state[modData.k] = modData.v || null
        }
    },
    actions: {
        update({ commit }, data) {
            return new Promise((resolve, reject) => {
                try {
                    console.log('updates...')
                    /* persist data to localstorage (commit call mutations) */
                    for (var k in data) {
                        var v = data[k]
                        console.log(`${k}: ${v}`)
                        if (v) {
                            commit('updateData', {
                                k: k,
                                v: v
                            })
                        } else {
                            // to delete
                            commit('updateData', {
                                k: k
                            })
                        }
                    }
                    resolve()
                } catch (e) {
                    reject(e)
                }
            })
        },
    },
    modules: {},
    plugins: [locStorage]
})