var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-toolbar',{attrs:{"tile":"","elevation":"1"}},[_c('v-app-bar-nav-icon'),_c('v-toolbar-title',[_vm._v("Account files")])],1),_c('v-card-text',[_c('v-container',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_vm._v(" Selected account: "+_vm._s(_vm.selectedAccount()))]),_c('v-row',[_vm._v(" Current directory: "+_vm._s(_vm.currentDirectory))]),_c('v-row',{staticClass:"mt-6"},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"success","disabled":_vm.isRootPath()},on:{"click":_vm.goToRootDirectory}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-home ")]),_vm._v(" Root directory ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","disabled":_vm.isRootPath()},on:{"click":_vm.goToParentDirectory}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-up-bold ")]),_vm._v(" Parent directory ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"id":"accounts_data_table","headers":_vm.headers,"items":_vm.files,"noData":"","loading":_vm.files_loading,"no-data-text":_vm.filesDataOptions.noDataText},on:{"contextmenu:row":_vm.show,"click:row":_vm.openDirectory},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 'directory')?_c('v-icon',[_vm._v("mdi-folder")]):_vm._e(),(item.type == 'file')?_c('v-icon',[_vm._v("mdi-file")]):_vm._e()]}},{key:"item.filesize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.filesize ? item.filesize : '-')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap !important"}},[_c('v-btn',{staticClass:"actionButtons ml-1 pa-0",attrs:{"depressed":"","small":"","dark":"","color":"primary","to":'account/' + item.username}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"actionButtons ml-1 pa-0",attrs:{"depressed":"","small":"","dark":"","color":"error"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true
                                _vm.userToDelete = item.username}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)}),_c('v-btn',{staticClass:"mt-5",attrs:{"to":"/secure/accounts","color":"primary"}},[_vm._v("Back")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }