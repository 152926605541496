<template>
    <v-app>
        <v-app-bar :color="topBarColor" app dark>
            <div class="d-flex align-center" v-if="showLogo">
                <!-- <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
                    transition="scale-transition"
                    width="40"
                /> -->
                <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    :src="publicPath + 'static/images/nagano_star.png'"
                    transition="scale-transition"
                    width="40"
                />

                <!-- <v-img
                    alt="Vuetify Name"
                    class="shrink mt-1 hidden-sm-and-down"
                    contain
                    min-width="100"
                    src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
                    width="100"
                /> -->
                <v-img
                    alt="Vuetify Name"
                    class="shrink mt-1 hidden-sm-and-down"
                    contain
                    min-width="100"
                    :src="publicPath + 'static/images/nagano_text.png'"
                    width="100"
                />
            </div>
            <v-spacer></v-spacer>
            <!-- <v-btn
                href="https://github.com/vuetifyjs/vuetify/releases/latest"
                target="_blank"
                text
            >
                <span class="mr-2">Latest Release</span>
                <v-icon>mdi-open-in-new</v-icon>
            </v-btn> -->
            <ThemeSwitchButton />
            <v-menu offset-y v-if="$store.state.token" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        elevation="0"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="ma-5"
                        ><v-icon> mdi-account-circle </v-icon></v-btn
                    >
                    <!-- <v-avatar v-bind="attrs" v-on="on">
                        <img
                            src="https://cdn.vuetifyjs.com/images/john.jpg"
                            alt="John"
                        />
                    </v-avatar> -->
                    <!-- <v-avatar v-bind="attrs" v-on="on">
                        <v-icon dark> mdi-account-circle </v-icon>
                    </v-avatar> -->
                    <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
                        Dropdown
                    </v-btn> -->
                </template>

                <v-list>
                    <v-list-item
                        link
                        @click="
                            $router
                                .push(
                                    '/secure/account/' + $store.state.username,
                                )
                                .catch(() => {})
                        "
                        >{{ $store.state.username }}</v-list-item
                    >
                </v-list>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item
                        v-for="(item, index) in items"
                        :key="index"
                        link
                        @click="callProfileURLAndMethods(item)"
                    >
                        <!-- <v-btn text @click="callProfileURLAndMethods(item)">{{
                            item.title
                        }}</v-btn> -->
                        <v-list-item-title
                            v-text="item.title"
                        ></v-list-item-title>

                        <v-divider
                            v-if="item.type == 'loggedaccount'"
                        ></v-divider>
                        <!-- <v-list-item-title>{{ item.title }}</v-list-item-title> -->
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-main class="main_overflow_auto">
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld'
import ThemeSwitchButton from './components/ThemeSwitchButton'
import auth from '@/security/auth'

export default {
    name: 'App',

    components: {
        // HelloWorld,
        ThemeSwitchButton,
    },

    data: () => ({
        showLogo: true,
        topBarColor: 'error',
        publicPath: process.env.BASE_URL,
        items: [
            {
                title: 'New account',
                url: '/secure/accounts/new',
            },
            {
                title: 'Accounts',
                url: '/secure/accounts',
            },
            {
                title: 'My Files',
                url: '/secure/myfiles',
            },
            {
                title: 'Logout',
                method: 'logout',
            },
        ],
    }),
    mounted() {
        const minutesIntervalToRenewToken = 1
        const oneMinute = 60 * 1000
        if (window.SFTP_minutesIntervalToRenewToken)
            clearInterval(window.SFTP_minutesIntervalToRenewToken)
        window.SFTP_minutesIntervalToRenewToken = setInterval(() => {
            if (this.$store.state.token) {
                console.log(new Date())
                console.log('**** Processing token renewal! ****')
                auth.renewToken()
                    .then((msg) => {
                        console.log(`Sucess: ${msg}`)
                    })
                    .catch((error) => {
                        console.log(`Failed: ${error}`)
                        if (error == 'token expired') {
                            // delete current token
                            this.$store
                                .dispatch('update', {
                                    token: null,
                                })
                                .then(() => {})
                            this.$router.push('/login').catch((err) => {
                                !err
                            })
                        }
                    })
            }
        }, minutesIntervalToRenewToken * oneMinute)
    },
    methods: {
        logout() {
            console.log('LOGOUT')
            // const vue = this
            this.$store
                .dispatch('update', {
                    token: null,
                })
                .then(() => {
                    this.$router.push('/login').catch(() => {})
                })
        },
        callProfileURLAndMethods(item) {
            !item
            if (item.method) {
                this[item.method]()
            } else if (item.url) {
                this.$router.push(item.url).catch(() => {})
            }
        },
    },
}
</script>

<style>
html {
    overflow: auto !important;
}

.main_overflow_auto {
    overflow: auto;
}
</style>
