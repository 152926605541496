<template>
    <div>
        <!-- Data Card -->
        <v-card outlined tile>
            <v-toolbar tile elevation="1">
                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                <v-toolbar-title>Accounts</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            to="/secure/accounts/new"
                            class="mx-2"
                            fab
                            color="success"
                            small
                        >
                            <v-icon color="white"> mdi-plus </v-icon>
                        </v-btn>
                    </template>
                    <span>New account</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-text-field
                        label="Filter by username"
                        prepend-inner-icon="mdi-magnify"
                        style="margin-bottom: 10px"
                        v-model="searchText"
                        :rules="searchTextRules"
                        min="3"
                        @keyup="updateSearch"
                        @blur="searchBlur"
                        counter
                    ></v-text-field>
                </v-form>

                <!-- <v-alert
                    v-model="errorDialog"
                    border="top"
                    color="red lighten-2"
                    dark
                    dismissible
                >
                    {{ errorDialogMessage }}
                </v-alert> -->
                <v-data-table
                    id="accounts_data_table"
                    :headers="headers"
                    :items="accounts"
                    class="elevation-1"
                    :loading="accounts_loading"
                    @contextmenu:row="show"
                >
                    <template v-slot:[`item.type`]="{}">
                        <v-icon>mdi-account-circle</v-icon>
                    </template>
                    <template v-slot:[`item.is_administrator`]="{ item }">
                        {{ item.is_administrator ? 'Yes' : 'No' }}
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <div style="white-space: nowrap !important">
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        depressed
                                        small
                                        dark
                                        color="grey darken-3"
                                        class="actionButtons"
                                        :to="'files/' + item.username"
                                        ><v-icon>mdi-folder</v-icon></v-btn
                                    >
                                </template>
                                <span>User files</span>
                            </v-tooltip>

                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        depressed
                                        small
                                        dark
                                        color="primary"
                                        class="actionButtons"
                                        :to="'account/' + item.username"
                                        ><v-icon>mdi-pencil</v-icon></v-btn
                                    >
                                </template>
                                <span>Edit account</span>
                            </v-tooltip>

                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        depressed
                                        small
                                        dark
                                        color="error"
                                        class="actionButtons"
                                        @click.stop="
                                            dialog = true
                                            userToDelete = item.username
                                        "
                                        ><v-icon>mdi-delete</v-icon></v-btn
                                    >
                                </template>
                                <span>Delete account</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <!-- End Data Card -->

        <v-menu
            v-model="showMenuRightClick"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
        >
            <v-list>
                <v-list-item
                    v-for="(item, index) in accountsActionItems"
                    :key="index"
                >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-overlay :value="overlay" z-index="100000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <!-- Delete Confirmation Dialog -->
        <v-dialog
            v-model="dialog"
            @click:outside="closeDialog"
            persistent
            max-width="450"
        >
            <v-card>
                <v-card-title class="headline">
                    Delete user {{ userToDelete }}
                </v-card-title>

                <v-card-text>
                    To confirm the deletion of this user, enter the username in
                    the field below, and then click on the confirm button.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" text @click="dialog = false">
                        Cancel
                    </v-btn>

                    <v-btn color="info" text @click="overlay = !overlay">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- END Delete Confirmation Dialog -->

        <!-- Delete Error Dialog -->
        <v-dialog v-model="errorDialog" persistent max-width="450">
            <v-card>
                <v-card-title class="headline"> Warning </v-card-title>
                <v-card-text>
                    {{ errorDialogMessage }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="errorDialog = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Delete Error Dialog -->
    </div>
</template>

<script>
export default {
    name: 'Accounts',

    components: {},

    data: () => ({
        overlay: false,
        dialog: false,
        errorDialog: false,
        errorDialogMessage: '',
        accounts_loading: false,
        filtered: false,
        userToDelete: null,
        showMenuRightClick: false,
        searchText: '',
        searchTextRules: [
            (v) =>
                v.length >= 3 ||
                'Minimum of 3 characters to perform the filtering',
        ],
        x: 0,
        y: 0,
        accountsActionItems: [
            { title: 'Click Me' },
            { title: 'Click Me' },
            { title: 'Click Me' },
            { title: 'Click Me 2' },
        ],
        headers: [
            { text: '', value: 'type' },
            {
                text: 'Username',
                align: 'start',
                sortable: true,
                value: 'username',
            },
            {
                text: 'Name',
                align: 'start',
                value: 'name',
                width: '100%',
            },
            { text: 'Is Admin?', value: 'is_administrator', align: 'center' },
            {
                text: 'Actions',
                value: 'action',
                sortable: false,
                align: 'center',
            },
        ],
        accounts: [],
    }),
    watch: {
        overlay(val) {
            if (val) {
                // to delete
                this.$http
                    .delete(`/secure/user/${this.userToDelete}`, {})
                    .then((results) => {
                        console.log(results.data)
                        this.errorDialogMessage = ''
                        this.overlay = false
                        this.dialog = false
                        this.errorDialog = false
                        this.reloadTable()
                    })
                    .catch((error) => {
                        console.log(error.response.data)
                        if (error.response.data.err) {
                            this.errorDialogMessage = error.response.data.err
                        }
                        this.overlay = false
                        this.dialog = false
                        this.errorDialog = true
                    })
            }
        },
    },
    mounted() {
        this.loadAllUsers()
    },
    methods: {
        closeDialog(e) {
            !e
            console.log('outside')
            console.log(e)
        },
        show(e, item) {
            !e
            !item
        },
        loadFilteredUsers() {
            // load filtered users
            if (this.searchText && this.searchText.length >= 3) {
                this.filtered = true
                this.accounts_loading = true
                this.$http
                    .get(`/secure/users/filter/${this.searchText}`, {})
                    .then((results) => {
                        console.log(results.data)
                        this.accounts_loading = false
                        this.accounts = results.data
                    })
            }
        },
        loadAllUsers() {
            // load all users
            if (!this.searchText || this.searchText.length < 3) {
                this.filtered = false
                this.accounts_loading = true
                this.$http.get('/secure/users', {}).then((results) => {
                    console.log(results.data)
                    this.accounts_loading = false
                    this.accounts = results.data
                })
            }
        },
        reloadTable() {
            if (!this.searchText || this.searchText.length < 3) {
                this.loadAllUsers()
            } else {
                this.loadFilteredUsers()
            }
        },
        updateSearch(e) {
            e.preventDefault()
            !e
            if (
                (!this.searchText || this.searchText.length < 3) &&
                this.filtered
            ) {
                this.loadAllUsers()
            } else {
                this.loadFilteredUsers()
            }
        },
        searchBlur(e) {
            e.preventDefault()
            !e
            if (!this.filtered) {
                this.searchText = ''
            }
            this.$refs.form.resetValidation()
        },
    },
}
</script>

<style scoped>
#accounts_data_table::v-deep th {
    white-space: nowrap !important;
}
.actionButtons {
    margin-left: 5px !important;
    min-height: 40px;
    min-width: 40px !important;
    width: 40px !important;
}
</style>
