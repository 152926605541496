const loadData = function(key) {
    var json = localStorage[key] || 'null'
    var parsedJson = null
    try {
        parsedJson = JSON.parse(json)
    } catch (e) {
        !e
        parsedJson = json
    }
    return parsedJson
}

const locStorage = store => {
    store.subscribe((mutation) => {
        if ('v' in mutation.payload) {
            var transformedVar = mutation.payload.v
            if (typeof transformedVar == 'object') {
                transformedVar = JSON.stringify(mutation.payload.v)
            }
            localStorage[mutation.payload.k] = transformedVar
        } else {
            delete localStorage[mutation.payload.k]
        }
    })
    store.loadAll = function() {
        console.log('loading all data')
        for (var k in store.state) {
            store.state[k] = loadData(k)
        }
    }
}

export { locStorage, loadData }