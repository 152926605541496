import axios from 'axios'
import appConfig from '../config'

let VueAxiosPlugin = {}

VueAxiosPlugin.install = (Vue, options) => {
    console.log('Initialized axios-vue plugin!')

    const defaultOptions = {
        baseURL: `${appConfig.BACKEND_BASE_URL}${appConfig.BACKEND_BASE_PATH}`,
        // request interceptor handler
        reqHandleFunc: config => config,
        reqErrorFunc: error => Promise.reject(error),
        // response interceptor handler
        resHandleFunc: response => response,
        resErrorFunc: error => Promise.reject(error)
    }

    const initOptions = {
        ...defaultOptions,
        ...options
    }

    const service = axios.create(initOptions)
    const http_obj = {
        get: (url, data, options) => {
            let axiosOpt = {
                ...options,
                ...{
                    method: 'get',
                    url: url,
                    params: data
                }
            }
            return service(axiosOpt)
        },
        post: (url, data, options) => {
            let axiosOpt = {
                ...options,
                ...{
                    method: 'post',
                    url: url,
                    data: data
                }
            }
            return service(axiosOpt)
        },
        put: (url, data, options) => {
            let axiosOpt = {
                ...options,
                ...{
                    method: 'put',
                    url: url,
                    data: data
                }
            }
            return service(axiosOpt)
        },
        delete: (url, data, options) => {
            let axiosOpt = {
                ...options,
                ...{
                    method: 'delete',
                    url: url,
                    data: data
                }
            }
            return service(axiosOpt)
        }
    }

    Vue.mixin({
        mounted() {
            // console.log('Mounted axios-vue plugin!')
            // auth token header
            if (this.$store && this.$store.state.token) {
                service.interceptors.request.use((config) => {
                    // console.log('interceptor')
                    // console.log(this.$store.state.token)
                    config.headers.Authorization = `Bearer ${this.$store.state.token}`
                    return config
                })
            }
        }
    })

    // Add a request interceptor
    service.interceptors.request.use(
        config => initOptions.reqHandleFunc(config),
        error => initOptions.reqErrorFunc(error)
    )
    // Add a response interceptor
    service.interceptors.response.use(
        response => initOptions.resHandleFunc(response),
        error => initOptions.resErrorFunc(error)
    )

    Vue.prototype.$axios = service
    Vue.prototype.$http = http_obj
}

// Auto-install
let GlobalVue = null
if (typeof window !== 'undefined') {
    GlobalVue = window.Vue
} else if (typeof global !== 'undefined') {
    GlobalVue = global.Vue
}

if (GlobalVue) {
    GlobalVue.use(VueAxiosPlugin)
}

export default VueAxiosPlugin

// import Vue from 'vue'
// import axios from 'axios'
// // import router from '@/router'

// const success = (res) => res
// const error = (err) => {
//     // if (401 === err.response.status) {
//     //     localStorage.removeItem(
//     //         '__' + process.env.VUE_APP_CI_PROJECT_NAME + '_app'
//     //     )
//     //     return router.push('/login')
//     // } else {
//     return Promise.reject(err)
//     // }
// }

// axios.interceptors.response.use(success, error)

// Vue.use({
//     install(Vue) {
//         Vue.prototype.$http = axios.create({
//             baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
//         })

//         console.log('axios created ', process.env.UG)

//         Vue.prototype.$http.interceptors.response.use(success, error)

//         // Vue.prototype.$http.interceptors.response.use(
//         //     (config) => {
//         //         if (config.config.headers['Authorization']) {
//         //             Vue.prototype.$http.defaults.headers.common['Authorization'] =
//         //     config.config.headers['Authorization']
//         //         }
//         //         return config
//         //     },
//         //     (error) => Promise.reject(error)
//         // )
//     },
// })
