<template>
    <v-container fluid fill-height class="loginOverlay">
        <v-layout flex align-center justify-center>
            <v-flex xs12 sm4>
                <v-card elevation="2" outlined shaped>
                    <v-toolbar elevation="1">
                        <v-toolbar-title
                            ><h4>SFTP Administration</h4></v-toolbar-title
                        >
                        <v-toolbar-items />
                    </v-toolbar>
                    <v-card-text class="pt-4">
                        <div>
                            <v-form v-model="valid" ref="form">
                                <v-text-field
                                    label="Enter your username"
                                    v-model="username"
                                    :rules="usernameRules"
                                    required
                                    autocorrect="off"
                                    autocapitalize="none"
                                ></v-text-field>
                                <v-text-field
                                    autocorrect="off"
                                    autocapitalize="none"
                                    label="Enter your password"
                                    v-model="password"
                                    min="6"
                                    :append-icon="
                                        hiddenPassword
                                            ? 'mdi-eye'
                                            : 'mdi-eye-off'
                                    "
                                    @click:append="
                                        () => (hiddenPassword = !hiddenPassword)
                                    "
                                    :type="hiddenPassword ? 'password' : 'text'"
                                    :rules="passwordRules"
                                    counter
                                    required
                                ></v-text-field>
                                <v-layout justify-space-between>
                                    <v-btn
                                        @click="submit"
                                        :class="{
                                            'error white--text': valid,
                                            disabled: !valid,
                                        }"
                                        >Login</v-btn
                                    >
                                    <!-- <a href="">Forgot Password</a> -->
                                </v-layout>
                            </v-form>
                            <v-alert
                                v-if="authError"
                                type="error"
                                class="mt-5"
                                icon="mdi-alert"
                                border="left"
                                prominent
                            >
                                <v-row align="center">{{ authError }}</v-row>
                            </v-alert>
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import auth from '@/security/auth'

export default {
    name: 'Login',
    data: () => ({
        valid: false,
        hiddenPassword: true,
        authError: null,
        password: '',
        passwordRules: [(v) => !!v || 'Password is required'],
        username: '',
        usernameRules: [
            (v) => !!v || 'Username is required',
            (v) =>
                (/^\w+([0-9]?\w+)*$/.test(v) && v.length <= 100) ||
                'Username must be valid',
        ],
        // mail rules: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
    }),
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                // this.$refs.form.$el.submit()
                auth.doAuth({
                    username: this.username,
                    password: this.password,
                })
                    .then((response) => {
                        console.log(response)
                        this.$store
                            .dispatch('update', {
                                username: this.username,
                                token: response.token,
                            })
                            .then(() => {
                                this.$router.push('/secure/')
                            })
                    })
                    .catch((data) => {
                        this.authError = data.error
                    })
            }
        },
        clear() {
            this.$refs.form.reset()
        },
    },
}
</script>
