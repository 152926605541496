<template>
    <v-container>
        Logged user: {{ $store.state.username }}
        <br />
        <br />
        <router-view></router-view>
    </v-container>
</template>

<script>
export default {
    components: {},
}
</script>
