import axios from 'axios'
import store from '@/store'
import config from '@/config'

const isAuthenticated = () => {
    const token = store.state.token
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${config.BACKEND_BASE_URL}${config.BACKEND_BASE_PATH}/is_valid`,
                {
                    token: token
                }
            )
            .then(function (response) {
                if (response.data.valid) {
                    resolve(response.data)
                } else {
                    console.log('rejected authentication')
                    reject(response.data)
                }
            })
            .catch(function () {
                resolve('unknown')
            })
    })
}

const renewToken = () => {
    const token = store.state.token
    return new Promise((resolve, reject) => {
        if (token) {
            axios
                .post(
                    `${config.BACKEND_BASE_URL}${config.BACKEND_BASE_PATH}/renew`,
                    {
                        token: token
                    }
                )
                .then(function (response) {
                    console.log(response.data)
                    if (response.data.valid && response.data.token) {
                        store
                            .dispatch('update', {
                                token: response.data.token,
                            })
                            .then(() => {
                                console.log('Token renewed!!!')
                            })
                            .catch(function () {
                                reject('failed to update stored token')
                            })
                    } else {
                        if (response.data.error && response.data.error == 'jwt expired') {
                            reject('token expired')
                        } else {
                            reject('token rejected')
                        }
                    }
                })
                .catch(function () {
                    reject('unknown')
                })
        } else {
            reject('empty token')
        }
    })
}

const doAuth = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${config.BACKEND_BASE_URL}${config.BACKEND_BASE_PATH}/auth`,
                {
                    username: credentials.username,
                    password: credentials.password,
                }
            )
            .then(function (response) {
                if (response.data.valid) {
                    if (response.data.is_admin) {
                        resolve(response.data)
                    } else {
                        reject({
                            error: 'This user is not an administrator'
                        })
                    }
                } else {
                    reject({
                        error: 'Invalid user or password'
                    })
                }
            })
            .catch(function (error) {
                console.log('Auth error:')
                if (error.response) {
                    console.log(error.response)
                    const httpStatusCode = error.response.status
                    // const httpStatusText = error.response.statusText
                    // const httpBody = error.response.data
                    reject({
                        error: (httpStatusCode == 401) ? 'not authorized' : 'authentication error, please try again later'
                    })
                } else {
                    // outros erros
                    reject({
                        error: 'error sending request, please try again later'
                    })
                }
            })
    })
}

export default {
    isAuthenticated,
    renewToken,
    doAuth,
}
