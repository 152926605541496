var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"color":_vm.topBarColor,"app":"","dark":""}},[(_vm.showLogo)?_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Vuetify Logo","contain":"","src":_vm.publicPath + 'static/images/nagano_star.png',"transition":"scale-transition","width":"40"}}),_c('v-img',{staticClass:"shrink mt-1 hidden-sm-and-down",attrs:{"alt":"Vuetify Name","contain":"","min-width":"100","src":_vm.publicPath + 'static/images/nagano_text.png',"width":"100"}})],1):_vm._e(),_c('v-spacer'),_c('ThemeSwitchButton'),(_vm.$store.state.token)?_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-5",attrs:{"elevation":"0","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account-circle ")])],1)]}}],null,false,2003041774)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$router
                            .push(
                                '/secure/account/' + _vm.$store.state.username
                            )
                            .catch(function () {})}}},[_vm._v(_vm._s(_vm.$store.state.username))])],1),_c('v-divider'),_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.callProfileURLAndMethods(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}}),(item.type == 'loggedaccount')?_c('v-divider'):_vm._e()],1)}),1)],1):_vm._e()],1),_c('v-main',{staticClass:"main_overflow_auto"},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }