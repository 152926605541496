<template>
    <!-- <v-container> -->
    <!-- <h1>Account: {{ $route.params.id }}</h1> -->
    <div>
        <!-- Data Card -->
        <v-card outlined tile>
            <v-toolbar tile elevation="1">
                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                <v-toolbar-title>{{ crudTitle }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-overlay
                        :value="loading"
                        :absolute="true"
                        z-index="100000"
                    >
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-text-field
                        v-if="!isEdit()"
                        v-model="username"
                        :counter="100"
                        :rules="usernameRules"
                        label="Username"
                        autocomplete="new-password"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        label="Name"
                        autocomplete="new-password"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="mail"
                        :rules="mailRules"
                        label="E-mail"
                        autocomplete="new-password"
                        required
                    ></v-text-field>

                    <v-checkbox
                        v-model="is_administrator"
                        label="Is an administrator?"
                        required
                    ></v-checkbox>

                    <v-switch
                        v-if="isEdit()"
                        v-model="changePassword"
                        flat
                        label="Change Password?"
                    ></v-switch>

                    <v-text-field
                        v-if="changePassword"
                        label="Password"
                        v-model="password"
                        min="6"
                        autocomplete="new-password"
                        :append-icon="
                            hiddenPassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="() => (hiddenPassword = !hiddenPassword)"
                        :type="hiddenPassword ? 'password' : 'text'"
                        :rules="passwordRules"
                        counter
                        required
                    ></v-text-field>

                    <v-text-field
                        v-if="changePassword"
                        label="Password confirmation"
                        v-model="confirmPassword"
                        min="6"
                        autocomplete="new-password"
                        :append-icon="
                            hiddenPassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="() => (hiddenPassword = !hiddenPassword)"
                        :type="hiddenPassword ? 'password' : 'text'"
                        :rules="passwordConfirmationRule"
                        counter
                        required
                    ></v-text-field>

                    <!-- <v-select
                        v-model="select"
                        :items="items"
                        :rules="[(v) => !!v || 'Item is required']"
                        label="Item"
                        required
                    ></v-select> -->

                    <v-btn
                        :disabled="!valid"
                        color="success"
                        class="mr-1"
                        @click="validate"
                    >
                        {{ saveLabel }}
                    </v-btn>

                    <v-btn
                        v-if="!isEdit()"
                        color="error"
                        class="mr-1"
                        @click="reset"
                    >
                        Reset Form
                    </v-btn>
                </v-form>
                <v-btn to="/secure/accounts" class="mt-1" color="primary"
                    >Back</v-btn
                >
            </v-card-text>
        </v-card>

        <!-- Action result Dialog -->
        <v-dialog v-model="actionDialog" persistent max-width="450">
            <v-card>
                <v-card-title class="headline">
                    {{ dialogTitle }}
                </v-card-title>
                <v-card-text>
                    {{ dialogMessage }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="actionClose">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Action result Dialog -->
    </div>
</template>

<script>
export default {
    name: 'Account',

    components: {},
    data: () => ({
        actionDialog: false,
        dialogTitle: '',
        dialogMessage: '',
        saveLabel: 'Save',
        loading: false,
        crudTitle: 'New account',
        changePassword: false,
        valid: true,
        username: '',
        usernameRules: [
            (v) => !!v || 'Username is required',
            (v) =>
                /^[0-9a-zA-Z]+$/.test(v) ||
                'Only letters and numbers are accepted',
            (v) =>
                (v && v.length >= 3) ||
                'Username must be greater or equal than 3 characters',
            (v) =>
                (v && v.length <= 100) ||
                'Username must be less than or equal to 100 characters',
        ],
        name: '',
        nameRules: [(v) => !!v || 'Name is required'],
        mail: '',
        mailRules: [
            (v) => !!v || 'E-mail is required',
            (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        hiddenPassword: true,
        password: '',
        confirmPassword: '',
        passwordRules: [
            (v) => !!v || 'Password is required',
            (v) =>
                (v && v.length >= 6) ||
                'Password must be greater or equal than 6 characters',
        ],
        select: null,
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
        is_administrator: false,
    }),
    computed: {
        passwordConfirmationRule() {
            return [
                (v) => !!v || 'Password is required',
                () => {
                    return (
                        this.password == this.confirmPassword ||
                        'Password must match'
                    )
                },
            ]
        },
    },
    updated() {
        if (this.isEdit()) {
            console.log('updated(): EDIT')
            this.saveLabel = 'Save'
            if (this.username != this.$route.params.id) {
                console.log(`Is edit (${this.username}): ${this.isEdit()}`)
                this.loading = true
                this.crudTitle = 'Edit user: ' + this.$route.params.id
                this.username = this.$route.params.id
                this.changePassword = false
                this.$http
                    .get(`/secure/user/${this.$route.params.id}`, {})
                    .then((user) => {
                        console.log(user.data)
                        this.loading = false
                        this.username = this.$route.params.id
                        this.name = user.data.name
                        this.mail = user.data.mail
                        this.is_administrator = user.data.is_administrator
                    })
            }
        } else if (this.saveLabel != 'Create') {
            this.$refs.form.reset()
            console.log('updated(): NEW')
            this.crudTitle = 'New account'
            this.username = ''
            this.changePassword = true
            this.saveLabel = 'Create'
        }
    },
    mounted() {
        console.log('mounted()')
        if (this.isEdit()) {
            this.saveLabel = 'Save'
            this.loading = true
            this.crudTitle = 'Edit user: ' + this.$route.params.id
            this.username = this.$route.params.id
            this.$http
                .get(`/secure/user/${this.$route.params.id}`, {})
                .then((user) => {
                    console.log(user.data)
                    this.loading = false
                    this.name = user.data.name
                    this.mail = user.data.mail
                    this.is_administrator = user.data.is_administrator
                })
        } else {
            this.crudTitle = 'New account'
            this.changePassword = true
            this.saveLabel = 'Create'
        }
    },
    methods: {
        actionClose() {
            this.actionDialog = false
        },
        isEdit() {
            return this.$route.params.id
        },
        validate() {
            if (this.$refs.form.validate()) {
                var userObj = {
                    name: this.name,
                    mail: this.mail,
                    is_administrator: this.is_administrator,
                }
                // username
                if (this.username && this.username != '') {
                    userObj = {
                        ...userObj,
                        username: this.username,
                    }
                }
                // password
                if (
                    this.password &&
                    this.password != '' &&
                    this.changePassword
                ) {
                    userObj = {
                        ...userObj,
                        password: this.password,
                    }
                }
                if (this.isEdit()) {
                    console.log('UPDATE')
                    this.loading = true
                    this.$http
                        .put('/secure/user', userObj)
                        .then((user) => {
                            console.log(user.data)
                            this.loading = false
                            this.dialogTitle = 'Action result'
                            this.dialogMessage = 'Update successfully'
                            this.actionDialog = true
                        })
                        .catch((err) => {
                            console.log(err.response.data)
                            this.loading = false
                            this.dialogTitle = 'Action result'
                            this.dialogMessage = 'Failed to update'
                            this.actionDialog = true
                        })
                } else {
                    console.log('NEW')
                    this.loading = true
                    this.$http
                        .post('/secure/user', userObj)
                        .then((user) => {
                            console.log(user.data)
                            this.loading = false
                            this.$router.push('/secure/accounts')
                        })
                        .catch((err) => {
                            console.log(err.response.data)
                            this.loading = false
                            this.dialogTitle = 'Action result'
                            this.dialogMessage = `Failed to create: ${err.response.data.err}`
                            this.actionDialog = true
                        })
                }
                console.log(userObj)
                // alert(JSON.stringify(userObj, null, 2))
            }
        },
        reset() {
            this.$refs.form.reset()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
    },
}
</script>
