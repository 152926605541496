<template>
    <v-tooltip v-if="$vuetify.theme.dark" bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="darkMode"
                ><v-icon dark>mdi-moon-waxing-crescent</v-icon></v-btn
            >
        </template>
        <span>Dark Mode On</span>
    </v-tooltip>
    <v-tooltip v-else bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="darkMode"
                ><v-icon dark>mdi-white-balance-sunny</v-icon></v-btn
            >
        </template>
        <span>Dark Mode Off</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'ThemeSwitchButton',
    inject: ['theme'],
    data: () => ({}),
    beforeMount() {
        this.$vuetify.theme.dark = this.$store.state.dark
    },
    methods: {
        darkMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            this.$store
                .dispatch('update', {
                    dark: this.$vuetify.theme.dark,
                })
                .then(() => {})
        },
    },
}
</script>
