<template>
    <div>
        <!-- Data Card -->
        <v-card outlined tile>
            <v-toolbar tile elevation="1">
                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                <v-toolbar-title>Account files</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-col cols="12" sm="12">
                        <v-row>
                            Selected account:
                            {{ selectedAccount() }}</v-row
                        >
                        <v-row>
                            Current directory: {{ currentDirectory }}</v-row
                        >
                        <v-row class="mt-6">
                            <v-btn
                                small
                                color="success"
                                class="mr-2"
                                @click="goToRootDirectory"
                                :disabled="isRootPath()"
                            >
                                <v-icon left> mdi-home </v-icon>
                                Root directory
                            </v-btn>
                            <v-btn
                                small
                                color="primary"
                                class="mr-2"
                                @click="goToParentDirectory"
                                :disabled="isRootPath()"
                            >
                                <v-icon left> mdi-arrow-up-bold </v-icon>
                                Parent directory
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-container>
                <v-data-table
                    id="accounts_data_table"
                    :headers="headers"
                    :items="files"
                    class="elevation-1 row-pointer"
                    noData=""
                    :loading="files_loading"
                    :no-data-text="filesDataOptions.noDataText"
                    @contextmenu:row="show"
                    @click:row="openDirectory"
                >
                    <template v-slot:[`item.type`]="{ item }">
                        <v-icon v-if="item.type == 'directory'"
                            >mdi-folder</v-icon
                        >
                        <v-icon v-if="item.type == 'file'">mdi-file</v-icon>
                    </template>
                    <template v-slot:[`item.filesize`]="{ item }">
                        {{ item.filesize ? item.filesize : '-' }}
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <div style="white-space: nowrap !important">
                            <v-btn
                                depressed
                                small
                                dark
                                color="primary"
                                class="actionButtons ml-1 pa-0"
                                :to="'account/' + item.username"
                                ><v-icon>mdi-pencil</v-icon></v-btn
                            >
                            <v-btn
                                depressed
                                small
                                dark
                                color="error"
                                class="actionButtons ml-1 pa-0"
                                @click.stop="
                                    dialog = true
                                    userToDelete = item.username
                                "
                                ><v-icon>mdi-delete</v-icon></v-btn
                            >
                        </div>
                    </template>
                </v-data-table>
                <v-btn to="/secure/accounts" class="mt-5" color="primary"
                    >Back</v-btn
                >
            </v-card-text>
        </v-card>
        <!-- <br />
        <br />
        <br />
        <br />
        <h3>User: client1</h3>
        <h3>Directory: /</h3>
        <v-data-table
            :headers="headers"
            :items="files"
            class="elevation-1"
            @contextmenu:row="show"
        >
            <template v-slot:[`item.type`]="{ item }">
                <v-icon v-if="item.type == 'directory'">mdi-folder</v-icon>
            </template>
        </v-data-table>
        <v-menu
            v-model="showMenuRightClick"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
        >
            <v-list>
                <v-list-item
                    v-for="(item, index) in filesActionItems"
                    :key="index"
                >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu> -->
    </div>
</template>

<script>
export default {
    name: 'Files',

    components: {},

    data: () => ({
        files_loading: false,
        showMenuRightClick: false,
        filesDataOptions: {
            noDataText: 'Empty folder',
        },
        x: 0,
        y: 0,
        filesActionItems: [{ title: 'Click Me' }, { title: 'Click Me 2' }],
        headers: [
            { text: '', value: 'type' },
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name',
                width: '100%',
            },
            { text: 'Size', value: 'filesize' },
            // {
            //     text: 'Actions',
            //     value: 'action',
            //     sortable: false,
            //     align: 'center',
            // },
        ],
        currentDirectory: '/',
        files: [],
    }),
    mounted() {
        this.loadDirectoryData()
    },
    methods: {
        isRootPath() {
            return this.currentDirectory == '/'
        },
        loadDirectoryData() {
            this.files_loading = true
            this.$http
                .post(`/secure/files/${this.selectedAccount()}`, {
                    path: this.currentDirectory,
                })
                .then((results) => {
                    this.files_loading = false
                    this.files = results.data
                })
        },
        goToParentDirectory() {
            var tmpDir = this.currentDirectory.replace(/\/[^/]+$/, '')
            if (tmpDir == '') {
                tmpDir = '/'
            }
            this.currentDirectory = tmpDir
            this.loadDirectoryData()
        },
        goToRootDirectory() {
            this.currentDirectory = '/'
            this.loadDirectoryData()
        },
        openDirectory(item) {
            if (item.type == 'directory') {
                console.log('opening directory...')
                if (this.currentDirectory == '/') {
                    this.currentDirectory += item.name
                } else {
                    this.currentDirectory += '/' + item.name
                }
                this.loadDirectoryData()
            }
            console.log(item.name)
        },
        selectedAccount() {
            return this.$route.params.id || this.$store.state.username
        },
        show(e, item) {
            e.preventDefault()
            !item
            // console.log('show')
            // console.log(e)
            // console.log(item)
            // this.showMenuRightClick = false
            // this.x = e.clientX
            // this.y = e.clientY
            // this.$nextTick(() => {
            //     this.showMenuRightClick = true
            // })
        },
    },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
#accounts_data_table::v-deep th {
    white-space: nowrap !important;
}
.actionButtons {
    margin-left: 5px !important;
    min-height: 40px;
    min-width: 40px !important;
    width: 40px !important;
}
</style>
