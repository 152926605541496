import Vue from 'vue'
import VueRouter from 'vue-router'
//import MainRoutes from "./mainRoutes";
// import Empty from '@/views/Empty.vue'
// import LoggedPages from '@/views/LoggedPages.vue'
// import appHeader from '@/views/Header.vue'
// import appFooter from '@/views/Footer.vue'
// import LoggedMainContent from '@/views/LoggedMainContent'
import Login from '@/views/Login.vue'
import Files from '@/views/secure/Files.vue'
import Accounts from '@/views/secure/Accounts.vue'
import Account from '@/views/secure/Account.vue'
import SecurePages from '@/views/secure/SecurePages.vue'

// import Home from '@/views/Home.vue'
// import Smallpage from '@/views/Smallpage.vue'
// // import Account from "@/views/Account.vue"
import auth from '@/security/auth'
// import Agenda from '@/views/Agenda'
// servicos
// import Servicos from "@/views/servicos/Servicos.vue"
// import ServicosHome from "@/views/servicos/Home.vue"
// import ServicosDocker from "@/views/servicos/Docker.vue"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { path: '/', redirect: '/secure/' },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                isPublic: true,
            },
        },
        {
            path: '/secure/',
            component: SecurePages,
            children: [
                {
                    name: 'accounts',
                    path: 'accounts',
                    alias: '',
                    component: Accounts,
                },
                {
                    name: 'account',
                    path: 'account/:id',
                    component: Account,
                },
                {
                    name: 'newaccount',
                    path: 'accounts/new',
                    component: Account,
                },
                {
                    name: 'files',
                    path: 'files/:id',
                    component: Files,
                },
                {
                    name: 'myfiles',
                    path: 'myfiles',
                    component: Files,
                },
                //         {
                //             name: 'LoggedMainContent',
                //             path: '/',
                //             components: {
                //                 default: LoggedMainContent,
                //                 appHeader,
                //                 appFooter,
                //             },
                //             children: [
                //                 {
                //                     name: 'home',
                //                     path: '/home',
                //                     alias: '/',
                //                     component: Home,
                //                 },
                //                 // {
                //                 //     name: 'account',
                //                 //     path: '/account',
                //                 //     component: () =>
                //                 //         import(
                //                 //             /* webpackChunkName: "account" */
                //                 //             '@/views/Account.vue'
                //                 //         ),
                //                 // },
                //                 {
                //                     name: 'smallpage',
                //                     path: '/smallpage',
                //                     component: Smallpage,
                //                 },
                //                 // {
                //                 //     name: 'test-components',
                //                 //     path: '/test-components',
                //                 //     component: () => import('../views/TestComponents'),
                //                 // },
                //                 // {
                //                 //     name: 'agenda',
                //                 //     path: '/agenda',
                //                 //     component: Agenda,
                //                 // },
                //             ],
                //         },
            ],
        },
    ],
})

router.beforeEach((to, from, next) => {
    console.log('Variable form App.vue')
    // console.log(router.app.$children[0].publicPath)
    // console.log('From:')
    // console.log(from)
    // console.log('To:')
    if (to.meta && to.meta.isPublic) {
        next()
    } else {
        if (router.app.$store.state.token) {
            auth.isAuthenticated().then((response) => {
                console.log(response)
                next()
            }).catch((error) => {
                console.log(to)
                console.log(error)
                router.push('/login').catch(err => { !err })
            })
        } else {
            router.push('/login').catch(err => { !err })   
        }
    }
})

export default router
